import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.input().value = sessionStorage.getItem('discount_input_value') || 0;
  }

  discount() {
    sessionStorage.setItem('discount_input_value', this.input().value);

    let discount = this.input().value
    let table = document.querySelector('table');
    let total = 0;

    table.querySelectorAll("input[type=number].table_item_price_input").forEach((v, k) => {
      v.value = v.value === '' ? 0 : (v.getAttribute('data-original') * (1-discount/100)).toFixed(2)
      let floatValue = parseFloat(v.value)

      let row = v.parentElement.parentElement.parentElement.parentElement.parentElement;

      let quantity = parseInt(row.querySelector("td:nth-child(5)").innerText);
      
      let total_price = row.querySelector("td:nth-child(7)");

      let row_total_price = floatValue * quantity
      total_price.innerText = (row_total_price).toFixed(2) + ' €';
      total += row_total_price;
      
    });

    table.querySelector("#table_total_price_ht").innerText = total.toFixed(2) + ' €';
  }

  input() {
    return this.element.querySelector('input');
  }
}