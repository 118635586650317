import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    connect() {}

    change(event) {
        let form = event.target.form

        if(!form) return;

        if(form.action.indexOf('?') == -1) {
            form.action = `${form.action}?button_action=false`
        } else {
            form.action = `${form.action}&button_action=false`
        }

        event.target.form.requestSubmit();
    }
}
