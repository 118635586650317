export function init(){
    let nav = document.querySelector("nav.conf-mobile-nav");
    if(!nav) return;
    let isSmall = true;
    function scrollConfMobileNavFn(){
        if(window.scrollY > 10 && isSmall){
            nav.classList.add("bg-white");
            nav.classList.remove("h-7rem");
            nav.querySelector(".header").classList.remove("h-7rem");
            nav.querySelector("img").classList.remove("w-12rem");
            isSmall = false;
        } else if(window.scrollY <= 10 && !isSmall) {
            nav.classList.remove("bg-white");
            nav.classList.add("h-7rem");
            nav.querySelector(".header").classList.add("h-7rem");
            nav.querySelector("img").classList.add("w-12rem");
            isSmall = true;
        }
    }
    scrollConfMobileNavFn();
    window.addEventListener("scroll", scrollConfMobileNavFn);
    nav.querySelectorAll(".nav-toggler").forEach(e=>{
       e.addEventListener("click",() =>{
           if(!nav.classList.contains("expand")){
               document.getElementsByTagName('body')[0].style.overflow = 'hidden';
           } else {
               document.getElementsByTagName('body')[0].style.overflow = 'visible'
           }
           nav.classList.toggle("expand");
       })
    })
}