export function init(){
    document.querySelectorAll("[id$='_validation'] input[type=number].validation_price").forEach((e)=>{
       e.addEventListener("change", (event) => {
         let table = event.currentTarget.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement;

         let total = 0;
         let totalTtc = 0;

         table.querySelectorAll("input[type=number].validation_price").forEach((v, k) => {
          total += parseFloat(v.value);
          let tva = parseFloat(v.getAttribute('data-tva-percent') || "0")/100 + 1;
          totalTtc += parseFloat(Math.round(v.value*tva*100)/100)
         });

         table.querySelector("#validation_total_ht").innerText = (Math.round(total*100)/100).toFixed(2) + ' €'
         let totalTtcElementCell = table.querySelector("#validation_total_ttc")
         let tva = parseFloat(totalTtcElementCell.getAttribute('data-tva-percent') || "0")/100 + 1;
         totalTtcElementCell.innerText =  totalTtc.toFixed(2) + ' €';
       });
    });
}