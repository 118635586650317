export function registerDynamicModalsEvent(){
    document.body.on('click', "[data-product-preview]", function () {
        document.body.insertAdjacentHTML('beforeend',`
            <div class="modal destroy-on-dismiss" id="product-preview">
                <div class="modal-content p-16">
                    <div class="border-b border-light-grey border-solid pb-8 flex justify-between text-structure mb-8">
                         <span class="font-semibold text-red">Apperçu</span>
                         <a modal-dismiss="product-preview" class="underline hover:text-red">Fermer</a>
                    </div>
                    <div class="grid lg:grid-cols-2 gap-2">
                        <div>
                            <div class="text-mini-title">Modèle</div>
                            imageeeee
                        </div>
                        <div class="grid grid-cols-3 gap-6 text-input-size">
                            <div>
                                <div class="text-mini-title">Dimensions</div>
                                <div><span class="text-red">a</span> : 100 mm</div>
                                <div><span class="text-red">b</span> : 100 mm</div>
                                <div><span class="text-red">c</span> : 100 mm</div>
                                <div><span class="text-red">d</span> : 100 mm</div>
                                <div><span class="text-red">e</span> : 100 mm</div>
                                <div class="text-mini-title mt-4">longueur</div>
                                2.42mL
                                <div class="text-mini-title mt-4">commentaire</div>
                                pour ma daronne
                            </div>
                            <div>
                                <div class="text-mini-title">épaisseur</div>
                                15/10è
                                <div class="text-mini-title mt-4">couleur</div>
                                <div class="text-center inline-flex items-center flex-col">
                                    <div class="w-20 h-20 rounded-full mb-2" style="background: #56071A"></div>
                                    #56071A <br> Rosewood
                                </div>
                            </div>
                            <div>
                                <div class="text-mini-title">laquage</div>
                                imaaaaageeeeee
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        `)
        setTimeout(() => {
            let modal = document.getElementById("product-preview");
            if(modal)modal.classList.add('show');
            if(modal)document.querySelector('div.modal-backdrop').classList.add('show');
        },5)
    })
}