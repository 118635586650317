export function init(){
    document.querySelectorAll(".production-table input[type=number]").forEach((e)=>{
       e.addEventListener("change", (event) => {
            let tr = event.currentTarget.parentElement.parentElement.parentElement.parentElement;
            let qty = tr.querySelector("td:nth-child(2)");
            let doing = tr.querySelector("td:nth-child(3) input");
            let done = tr.querySelector("td:nth-child(4) input");
            let rem = tr.querySelector("td:nth-child(5)");
            if(doing.value > (qty.innerText - done.value)) doing.value = (qty.innerText - done.value);
            if(doing.value < 0 ) doing.value = 0;
            if(done.value > (qty.innerText - doing.value)){
                if(done.value > qty.innerText){
                    doing.value = 0; done.value = qty.innerText;
                } else {
                    doing.value = qty.innerText -  done.value
                }
            }
            if(done.value < 0 ) done.value = 0;
            rem.innerText = qty.innerText - done.value - doing.value;
            if(rem.innerText > 0) tr.parentElement.parentElement.querySelectorAll(`thead tr th input[type=checkbox]`).forEach((v, k) => {
              v.checked = false;
            })
       });
    });

    document.querySelectorAll(".production-table input[type=checkbox]").forEach((e)=>{
      e.addEventListener("change", (event) => {
        let checked = event.currentTarget.checked;
        if(!checked) return;
        let table = event.currentTarget.parentElement.parentElement.parentElement.parentElement.parentElement;
        let column = 0;
        if(event.currentTarget.id === "pending") column = 3;
        if(event.currentTarget.id === "produced") column = 4;
        if(column === 4) table.querySelector(`#pending`).checked = false;

        if(column != 0) {
          table.querySelectorAll(`tbody tr`).forEach((v, k) => {
            let inputToChange = v.querySelector(`td:nth-child(${column}) input[type=number]`);
            inputToChange.value = 99999999;
            let event =document.createEvent("HTMLEvents");
            event.initEvent("change",false, true);
            inputToChange.dispatchEvent(event);
          })
        }
      });
   });
}