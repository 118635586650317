export function init(){
    let nav = document.querySelector("div.vedrenne-navbar");

    if(nav === undefined || nav === null) return false;

    let sideBackdrop = document.querySelector("div.side-backdrop");
    let sidebar = document.querySelector('div.vedrenne-sidebar');
    nav.querySelectorAll(".side-toggler").forEach(e=>{
        e.addEventListener("click",() =>{
            if(!sidebar.classList.contains("show")){
                sideBackdrop.classList.add("show");
            } else {
                sideBackdrop.classList.remove("show");
            }
            sidebar.classList.toggle("show");
        })
    })
    document.querySelector(".side-backdrop").addEventListener("click",() =>{
        if(sidebar.classList.contains("show")){
            sideBackdrop.classList.remove("show");
            sidebar.classList.remove("show");
        }
    })
}