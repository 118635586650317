// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import all from "./main/all";
import { Application } from "@hotwired/stimulus"

const application = Application.start()

import QuantityInputController from "./controllers/quantity_input_controller"
application.register("quantity_input", QuantityInputController)

import TableWithPriceInputController from "./controllers/table_with_price_input_controller"
application.register("table_with_price_input", TableWithPriceInputController)

import ClearSessionStorageController from "./controllers/clear_session_storage_controller"
application.register("clear_session_storage", ClearSessionStorageController)

import DiscountInputController from "./controllers/discount_input_controller"
application.register("discount_input", DiscountInputController)

import ThicknessFieldController from "./controllers/thickness_field_controller";
application.register("thickness_field", ThicknessFieldController)

Rails.start()
Turbolinks.start()
ActiveStorage.start()
all();

application.debug = false
window.Stimulus = application

export { application }

window.Rails = Rails;
window.Turbolinks = Turbolinks;

window.goto = path => window.location.assign(path);

document.addEventListener("turbolinks:before-visit", function() {
  Turbolinks.clearCache();
})

