export function init(){
    document.body.on("click", ".vedrenne-input.password-input button",function(){
        let input = this.previousElementSibling;
        input.type= input.type==="password"?"text":"password";
    });
    document.body.on("click", ".vedrenne-input .number-input button",function(){

        let div = this.parentElement;
        let input = div.querySelector("input");
        let counter = 0;

        if(div.querySelector("button:first-of-type") === this){
          input.value --;
          if(input.hasAttribute("min") ? input.value < input.getAttribute("min") : false) {
            input.value = input.getAttribute("min") ;
          } else {
            counter = -1;
          }
        }
        if(div.querySelector("button:last-of-type") === this){
          input.value ++;
          if(input.hasAttribute("max") ? input.value > input.getAttribute("max") : false) {
            input.value = input.getAttribute("max");
          } else {
            counter = 1;
          }
        }

        //let counter_elem = document.getElementById('configurator_cart_items_counter')
        // if(counter_elem !== null) { counter_elem.innerHTML = parseInt(counter_elem.innerHTML) + counter }

        let articles_label_elem = document.getElementById('articles_label')
        //if(articles_label_elem !== null) { parseInt(counter_elem.innerHTML) === 1 ? articles_label_elem.innerHTML = 'article' : articles_label_elem.innerHTML = 'articles' }

        let event = document.createEvent("HTMLEvents");
        event.initEvent("change",false, true);
        input.dispatchEvent(event);
    });
    document.body.on("click", ".vedrenne-input.file-input input[type=file]",function(){
        this.addEventListener("change", ({currentTarget}) => {
            let label = currentTarget.parentElement.querySelector("label");
            label.innerText = "";
            label.innerHtml = "";
            let span=document.createElement('span');
            if(currentTarget.files.length === 1){
              span.innerText = `${currentTarget.files[0].name}`;
              label.append(span);
            } else {
              span.innerText = `Choisissez un fichier`
              label.append(span);
            }
        }, {once: true});
    });
}