export function init(){
    document.body.on("click", "[modal-show]",function(){
        if(this.getAttribute("modal-show")){
            let modal = document.getElementById(this.getAttribute("modal-show"));
            if(modal)modal.classList.add('show');
            if(modal)document.querySelector('div.modal-backdrop').classList.add('show');
        }
    });
    document.body.on("click", "[modal-dismiss]",function(){
        if(this.getAttribute("modal-dismiss")){
            let modal = document.getElementById(this.getAttribute("modal-dismiss"));
            if(modal)if(modal.classList.contains("undismissable"))return;
            if(modal)modal.classList.remove('show');
            if(modal)document.querySelector('div.modal-backdrop').classList.remove('show');
            if(modal)if(modal.classList.contains("destroy-on-dismiss")) {
                setTimeout(() => {modal.remove()}, 300);
            }
        }
    });
    document.body.on("click", "div.modal-backdrop",function(){
        document.querySelectorAll('div.modal.show').forEach(e=>{
            if(e.classList.contains("undismissable"))return;
            e.classList.remove('show');
            this.classList.remove('show');
            if(e.classList.contains("destroy-on-dismiss")) {
                setTimeout(() => {e.remove()}, 300);
            }
        })
    });
    window.hideModals = () => {
        document.querySelector('body div.modal-backdrop').click();
    }
    window.showModals = (modal_id) => {
      document.getElementById(modal_id).classList.add('show');
      document.querySelector('div.modal-backdrop').classList.add('show')
    }
}