export function init(){
    document.body.on("click", "div.vedrenne-tabs[aria-controls]>div.tab:not(.active)[data-tab]",function(){
        let controlledContent = document.getElementById(this.parentElement.getAttribute("aria-controls"));
        if(!controlledContent) return;
        let tabContent = controlledContent.querySelector(`div.tab-content[data-tab="${this.getAttribute("data-tab")}"]`);
        if(!tabContent) return;
        this.parentElement.querySelectorAll('div.tab.active').forEach(e=>{e.classList.remove('active')});
        controlledContent.querySelectorAll('div.tab-content.active').forEach(e=>{e.classList.remove('active')});
        this.classList.add('active');
        tabContent.classList.add('active');
    });
}