import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    let input = this.input();

    if(sessionStorage.getItem(input.id)) {
      let hash = this.inputValueParser(sessionStorage.getItem(input.id));

      input.value = input.getAttribute('time_id') < hash[1] ? hash[0] : input.value 
    }

    this.updateEditCartPath(input.value);
    this.updateDuplicatePath(input.value);
    this.updateCartItemsCounter(input.value);

    document.querySelectorAll('a:not(.vedrenne-button):not([data-method])').forEach((button) => {
      button.addEventListener('click', (event) => {
        event.preventDefault();
        this.submitPatchCartForm(button);
      })
    });
  }

  submitPatchCartForm(button) {
    let form = this.element.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement;

    var hidden_from_stimulus = document.createElement("input")
    hidden_from_stimulus.name = "from_stimulus"
    hidden_from_stimulus.id = "from_stimulus"
    hidden_from_stimulus.value = "true"

    var hidden_href_redirection = document.createElement("input")
    hidden_href_redirection.name = "href_redirection"
    hidden_href_redirection.id = "href_redirection"
    hidden_href_redirection.value = button.getAttribute('href')

    form.appendChild(hidden_from_stimulus)
    form.appendChild(hidden_href_redirection)
    form.submit();
  }

  inputValueParser(value) {
    return value.split(':');
  }

  nowInSecond() {
    return Math.floor(Date.now()/1000);
  }

  decrement() {
    let input = this.input();
    let newValue = parseInt(input.value) - 1
    sessionStorage.setItem(input.id, `${newValue}:${this.nowInSecond()}`);
    this.updateEditCartPath(newValue);
    this.updateDuplicatePath(newValue);
    this.updateCartItemsCounter(-1);
  }

  increment() {
    let input = this.input();
    let newValue = parseInt(input.value) + 1;
    sessionStorage.setItem(input.id, `${newValue}:${this.nowInSecond()}`)
    this.updateEditCartPath(newValue);
    this.updateDuplicatePath(newValue);
    this.updateCartItemsCounter(1);
  }

  manualChange() {
    let input = this.input();
    let newValue = parseInt(input.value);
    let diff = newValue - this.inputValueParser(sessionStorage.getItem(input.id))[0];

    sessionStorage.setItem(input.id, `${newValue}:${this.nowInSecond()}`)
    this.updateDuplicatePath(newValue);
    this.updateCartItemsCounter(diff);
  }

  input() {
    return this.element.parentElement.querySelector("input");
  }

  duplicateLink() {
    let tr = this.element.parentElement.parentElement.parentElement;

    let link = tr.querySelector("#duplicate-link");
    return link;
  }

  editLink() {
    let tr = this.element.parentElement.parentElement.parentElement;

    let link = tr.querySelector("#edit-link");
    return link;
  }

  cartItemCounter() {
    return document.getElementById('configurator_cart_items_counter');
  }

  cartItemCounterLabel() {
    return document.getElementById('articles_label');
  }

  updateDuplicatePath(value) {
    let link = this.duplicateLink();

    let decomposePath = link.href.split('=');
    decomposePath[1] = value

    link.href = decomposePath.join('=');
  }

  updateEditCartPath(value) {
    let link = this.editLink();

    let decomposePath = link.href.split('=');
    decomposePath[1] = value

    link.href = decomposePath.join('=');
  }

  updateCartItemsCounter(value) {
    let counter = this.cartItemCounter();
    let label = this.cartItemCounterLabel();

    let counterValue = parseInt(counter.innerHTML) + parseInt(value);

    counter.innerHTML = counterValue.toString();
    label.innerHTML = (counterValue > 1) ? 'articles' : 'article'

  }
}
