import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    let input = this.input();
    let priceEl = this.price_total_element();

    let session_value = sessionStorage.getItem(this.compute_session_storage_item(input));

    if(session_value){
      let split = session_value.split(':');
      input.value = split[0];
      priceEl.innerText = split[1];
    }

    let discount = sessionStorage.getItem('discount_input_value') || 0;

    input.value = (parseFloat(input.value) * (1-discount/100)).toFixed(2);
    priceEl.innerText = (parseFloat(priceEl.innerText) * (1-discount/100)).toFixed(2);

    if(this.element.dataset["initialUpdate"]) {this.updateRowTotalPrice(null, true)};
  }

  updateRowTotalPrice(event, onConnect = false) {
    let discount = sessionStorage.getItem('discount_input_value') || 0;

    let table = this.table_element();
    let total = 0.0;
    let total_ttc = 0.0;
    table.querySelectorAll("input[type=number].table_item_price_input").forEach((v, k) => {
      if (!onConnect) {v.value = v.value === '' ? 0 : v.value * (1 - discount/100)}

      let tva_percent = v.getAttribute('data-tva-percent') || 0;
      let floatValue = parseFloat(v.value)

      let row = v.parentElement.parentElement.parentElement.parentElement.parentElement;

      let quantity = parseInt(row.querySelector("#table_quantity").innerText)

      let total_price = row.querySelector("#table_item_price_total");

      total += floatValue * quantity;
      total_ttc += (floatValue * quantity) * (1 + tva_percent/100);
      total_price.innerText = (floatValue*quantity).toFixed(2) + ' €';

      let originalPrice = discount == 0 ? floatValue : floatValue / (1 - discount/100);

      if (!onConnect) {
        sessionStorage.setItem(this.compute_session_storage_item(v), `${originalPrice}:${total_price.innerText}`);
      }

      v.setAttribute('data-original', originalPrice);
    });

    table.querySelector("#table_total_price_ht").innerText = total.toFixed(2) + ' €';
    
    let table_total_price_ttc = table.querySelector("#table_total_price_ttc");

    if (table_total_price_ttc) {
      table_total_price_ttc.innerText = total_ttc.toFixed(2) + ' €';
    }
  }

  compute_session_storage_item(input) {
    return `${input.id}_${this.hidden_id_value(input)}`;
  }

  hidden_id_value(el) {
    return el.parentElement.parentElement.parentElement.parentElement.parentElement.querySelector('input[type="hidden"]').value;
  }

  input() {
    return this.element.querySelector('input');
  }

  price_total_element() {
    return this.element.parentElement.parentElement.querySelector('#table_item_price_total');
  }

  table_element() {
    return this.element.parentElement.parentElement.parentElement.parentElement;
  }

  form_element() {
    return this.table_element().parentElement.parentElement;
  }

  invoice_edit_form_session_storage_id() {
    return this.form_element().getAttribute('action');
  }
}