import delegateInit from "./delegateInit"
import {init as initFiltersDropDowns} from "./common/filterDropDown";
import {init as initModals} from "./common/modal";
import {init as initInputs} from "./common/input";
import {init as initTabs} from "./common/tabs";
import {init as initRipple} from "./common/ripple";
import {registerDynamicModalsEvent as registerOrderProductPreviewDynamicModalsEvent} from "./common/orderProductPreview";
import {init as initDomManipulation} from "./common/DOMManipulation";
import {init as initOrderProductionManagementModal} from "./common/orderProductionManagementModal";
import {init as initOrderUpdateConfirmationTable} from "./common/orderUpdateConfirmationTable";
import {init as initDropDowns} from "./common/DropDown";
import {init as initConfiguratorNav} from "./common/ConfiguratorNav";
import {init as initDashboardNav} from "./common/DashboardNav";
import Glide, {Breakpoints, Controls, Swipe} from "./glide.modular.esm";

export default function (){
    window.Glide = Glide;
    window.GlidePlugins = {Breakpoints, Controls, Swipe};
    function ready(){
        delegateInit();
        initFiltersDropDowns();
        initModals();
        initInputs();
        initTabs();
        initRipple();
        registerOrderProductPreviewDynamicModalsEvent();
        initDomManipulation();
        initOrderProductionManagementModal();
        initOrderUpdateConfirmationTable();
        initDropDowns();
        initConfiguratorNav();
        initDashboardNav();
    }
    //document.addEventListener("DOMContentLoaded", ready, false);
    document.addEventListener("turbolinks:load", ready, false);
}