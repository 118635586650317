export default function (){
    HTMLElement.prototype.on = function(evt, sel, handler, noMatch = false) {
        this.addEventListener(evt, function(event) {
            let t = event.target;
            let matched = false;
            while (t && t !== this) {
                if(t.matches(sel)){
                    matched = true
                }
                if(matched && !noMatch){handler.call(t,event);break;}
                t = t.parentNode;
            }
            if(!matched && noMatch){
                this.querySelectorAll(sel).forEach(e=>{
                    handler.call(e,event);
                })
            }
        });
    }
}