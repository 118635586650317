//if outer is true, the function redefine container and content, else, it redefine only content
export function init(){
    window.setHTML = (query, html, outer = false) => {
        let element = document.querySelector(query);
        if (element) {
            element[outer?"outerHTML":"innerHTML"] = html;
        }
    }

    window.setFlashMessage = (flash) => {
      setHTML("#flash_message", flash);
    }

    window.setButtonState = (query, disable) => {
      let element = document.querySelector(query);
      if (element) {
        if (disable) {
          element.classList.add('disabled');
        } else {
          element.classList.remove('disabled');
        }
      }
    }
}